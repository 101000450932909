import { LinkRegular } from '@fluentui/react-icons';
import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, where, Timestamp, addDoc, connectFirestoreEmulator, onSnapshot } from 'firebase/firestore';
import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './Legal.module.css';
import { UserContext } from '../UserContext';
import LegalTable from '../components/legal/LegalTable';

const db = getFirestore();
//connect to firestore emulators
// connectFirestoreEmulator(db, '127.0.0.1', 8080);

export default function Legal() {
    const { userLevel, userEmail } = useContext(UserContext);

    const [ultimosCasos, setUltimosCasos] = useState([]);
    const [selection, setSelection] = useState('MIS CASOS');
    const [admins, setAdmins] = useState([]);
    // Crear caso
    const [afiliado, setAfiliado] = useState('');
    const [tipo, setTipo] = useState('');
    // Display reference
    const [afiliadoNombre, setAfiliadoNombre] = useState('');
    const [afiliadoApellidos, setAfiliadoApellidos] = useState('');
    // Search input
    const [searchCriteria, setSearchCriteria] = useState('afiliado');
    const [search, setSearch] = useState('');
    // Notification badge
    const [badgeCount, setBadgeCount] = useState(0);

    // useEffect(() => {
    //     async function fetchData() {
    //         var q = query(collection(db, "legales"), orderBy("fechaCreacion", "desc"), limit(5));
    //         switch (selection) {
    //             case 'RECIENTES':
    //                 q = query(collection(db, "legales"), orderBy("fechaCreacion", "desc"), limit(5));
    //                 break;
    //             case 'POR ASIGNAR':
    //                 q = query(collection(db, "legales"), where("estado", "==", "POR ASIGNAR"));
    //                 break;
    //             case 'ACTIVO':
    //                 q = query(collection(db, "legales"), where("estado", "==", "ACTIVO"));
    //                 break;
    //             case 'ARCHIVADO':
    //                 q = query(collection(db, "legales"), where("estado", "==", "ARCHIVADO"));
    //                 break;
    //             default:
    //                 break;
    //         }
    //         const querySnapshot = await getDocs(q);
    //         var array = [];
    //         querySnapshot.forEach((doc) => {
    //             var data = doc.data();

    //             array.push({
    //                 id: doc.id,
    //                 ...data,
    //             });
    //         });
    //         setUltimosCasos(array);
    //     }
    //     fetchData();
    // }, [selection]);

    useEffect(() => {
        function fetchData() {
            var q = query(collection(db, "legales"), orderBy("fechaCreacion", "desc"), limit(5));
            switch (selection) {
                case 'RECIENTES':
                    q = query(collection(db, "legales"), orderBy("fechaCreacion", "desc"), limit(5));
                    break;
                case 'POR ASIGNAR':
                    q = query(collection(db, "legales"), where("estado", "==", "POR ASIGNAR"), orderBy("fechaCreacion", "desc"));
                    break;
                case 'MIS CASOS':
                    q = query(collection(db, "legales"), where("estado", "==", "ACTIVO"), where("abogado", "==", userEmail));
                    break;
                case 'ACTIVO':
                    q = query(collection(db, "legales"), where("estado", "==", "ACTIVO"), orderBy("fechaImportante", "desc"));
                    break;
                case 'ARCHIVADO':
                    q = query(collection(db, "legales"), where("estado", "==", "ARCHIVADO"));
                    break;
                case 'FILTRAR':
                    q = query(collection(db, "legales"), where(searchCriteria, "==", search));
                    break;
                default:
                    break;
            }
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var array = [];
                querySnapshot.forEach((doc) => {
                    var data = doc.data();

                    array.push({
                        id: doc.id,
                        ...data,
                    });
                });
                setUltimosCasos(array);
            });

            // Cleanup function
            return () => unsubscribe();
        }
        fetchData();
    }, [selection]);



    useEffect(() => {
        // Buscar casos con estado visto y asignados a userEmail para contarlos
        async function fetchData() {
            const q = query(collection(db, "legales"), where("abogado", "==", userEmail), where("visto", "==", false));
            const querySnapshot = await getDocs(q);
            let count = 0; // Variable para contar los documentos

            querySnapshot.forEach(() => {
                count++; // Incrementa el contador por cada documento encontrado
            });

            console.log(count); // Aquí puedes reemplazar esto para actualizar el estado que maneja el badge de notificación, por ejemplo, setBadgeCount(count);
            setBadgeCount(count);
        }
        fetchData();
    }, [userEmail]);

    useEffect(() => {
        // when afiliado changes and length is more than 7 characters, query firestore on usuarios collection and get the name and last name, you should compare afiliado with doc.id

        async function fetchData() {
            const docRef = doc(db, "usuarios", afiliado);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setAfiliadoNombre(docSnap.data().nombre);
                setAfiliadoApellidos(docSnap.data().apellidos);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                setAfiliadoNombre('');
                setAfiliadoApellidos('');
            }
        }

        if (afiliado.length > 8) {
            fetchData();
        }
    }, [afiliado]);

    const crearCaso = async () => {
        try {
            const docRef = await addDoc(collection(db, "legales"), {
                afiliado: afiliado,
                tipo: tipo,
                estado: 'POR ASIGNAR',
                fechaCreacion: Timestamp.now(),
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    }

    const showDialog = () => {
        var element = document.getElementById('legal-create-modal');
        element.showModal()
    }

    const [adminList, setAdminList] = useState([]);

    useEffect(() => {
        const q = query(collection(db, "admins"), where("level", "in", [8, 9]));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({
                    key: doc.id,
                    name: doc.data().name,
                });
            });
            setAdminList(docs);
        });
        return () => unsubscribe();
    }, []);

    // const queryFilter = async () => {
    //     const q = query(collection(db, "legales"), where(searchCriteria, "==", search), limit(10));

    //     const querySnapshot = await getDocs(q);
    //     querySnapshot.forEach((doc) => {
    //         console.log(doc.id, " => ", doc.data());
    //     });
    // }

    const queryFilter = async () => {
        const q = query(collection(db, "legales"), where(searchCriteria, "==", search));

        const querySnapshot = await getDocs(q);
        const newArray = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        setUltimosCasos(newArray);
    }

    const downloadCSV = () => {
        var csv = 'Afiliado, Fecha importante, Acción, Tipo, Proceso, Abogado, Creación, Id\n';
        ultimosCasos.forEach(function (row) {
            var fechaImportante = row.fechaImportante ? new Date(row.fechaImportante.seconds * 1000).toLocaleDateString() : undefined;
            var fechaCreacion = new Date(row.fechaCreacion.seconds * 1000).toLocaleDateString();
            csv += row.afiliado + ', ' + fechaImportante + ', ' + row.accion + ', ' + row.tipo + ', ' + row.proceso + ', ' + row.abogado + ', ' + fechaCreacion + ', ' + row.id + '\n';
        });

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'casos-legales.csv';
        hiddenElement.click();
    }


    const [afiliadoNombres, setAfiliadoNombres] = useState({});

    useEffect(() => {
        const fetchAfiliadoNombres = async () => {
            const nombres = {};
            for (const caso of ultimosCasos) {
                if (caso.afiliado) {
                    const docRef = doc(db, "usuarios", caso.afiliado);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        nombres[caso.afiliado] = docSnap.data().nombre;
                    } else {
                        nombres[caso.afiliado] = 'No such document!';
                    }
                } else {
                    nombres[caso.afiliado] = 'No definido';
                }

            }
            setAfiliadoNombres(nombres);
        };

        fetchAfiliadoNombres();
    }, [ultimosCasos, selection]);

    return (
        <div className='page'>
            <div className={styles.headline}>
                <h1>Casos legales</h1>
            </div>

            <button className='extended-fab event-fab' style={{ zIndex: '1000' }} onClick={showDialog}>
                <span className='material-symbols-rounded'>add</span>
                Crear caso legal
            </button>


            <div className='casos-tabs'>
                <button className={(selection === 'RECIENTES') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('RECIENTES')}>
                    <span className='material-symbols-rounded'>history</span>
                    Recientes
                </button>
                <button className={(selection === 'POR ASIGNAR') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('POR ASIGNAR')}>
                    <span className='material-symbols-rounded'>assignment</span>
                    Por asignar
                </button>
                <button className={(selection === 'MIS CASOS') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('MIS CASOS')}>
                    {
                        badgeCount > 0 &&
                        <div className={styles.badge}>{badgeCount}</div>
                    }
                    <span className='material-symbols-rounded'>person</span>
                    Mis casos

                </button>
                <button className={(selection === 'ACTIVO') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('ACTIVO')}>
                    <span className='material-symbols-rounded'>assignment_turned_in</span>
                    Activos
                </button>
                <button className={(selection === 'FILTRAR') ? 'filled-tonal-button' : 'elevated-button'} onClick={() => setSelection('FILTRAR')}>
                    <span className='material-symbols-rounded'>filter_alt</span>
                    Filtrar
                </button>
            </div>


            {
                selection === 'RECIENTES' &&
                <p>Últimos 5 casos creados</p>
            }
            {
                selection === 'POR ASIGNAR' &&
                <p>Casos estado POR ASIGNAR ordenados por fecha de creación</p>
            }
            {
                selection === 'MIS CASOS' &&
                <p>Casos estado ACTIVO asignados a {userEmail}</p>
            }
            {
                selection === 'ACTIVO' &&
                <p>Casos ACTIVOS ordenados por fecha importante</p>
            }


            {
                selection === 'FILTRAR' &&
                <>

                    <div onChange={e => setSearchCriteria(e.target.value)} style={{ marginBottom: '1rem' }}>
                        <input type="radio" value="afiliado" name="searchCriteria" checked={searchCriteria === 'afiliado'} onChange={e => setSearchCriteria(e.target.value)} /> Afiliado
                        {/* <input type="radio" value="accion" name="searchCriteria" checked={searchCriteria === 'accion'} onChange={e => setSearchCriteria(e.target.value)} /> Acción */}
                        {/* <input type="radio" value="tipo" name="searchCriteria" checked={searchCriteria === 'tipo'} onChange={e => setSearchCriteria(e.target.value)} /> Tipo */}
                        {/* <input type="radio" value="proceso" name="searchCriteria" checked={searchCriteria === 'proceso'} onChange={e => setSearchCriteria(e.target.value)} /> Proceso */}
                        <input type="radio" value="abogado" name="searchCriteria" checked={searchCriteria === 'abogado'} onChange={e => setSearchCriteria(e.target.value)} /> Abogado
                    </div>

                    {
                        searchCriteria === 'abogado' &&
                        <div className='input-container'>
                            <select id="abogado" name="abogado" value={search} onChange={e => setSearch(e.target.value)}>
                                <option value="" disabled>---Selecciona Abogado---</option>
                                {
                                    adminList.map(d =>
                                        <option key={d.key} value={d.key}>{d.name}</option>
                                    )
                                }
                            </select>
                        </div>
                    }

                    {
                        searchCriteria !== 'abogado' &&
                        <div className='input-container' style={{ width: 'fit-content' }}>
                            <input className='input-normal' id={"search"} type="text" value={search} onChange={e => setSearch(e.target.value)} />
                            <label className='input-label' htmlFor={"search"}>Ingresa el valor</label>
                        </div>
                    }

                    <button className='filled-button' onClick={() => queryFilter()} style={{ marginTop: '1rem' }}>
                        <span className='material-symbols-rounded'>search</span>
                        Buscar
                    </button>

                    <button className='elevated-button' onClick={downloadCSV} style={{ marginTop: '1rem' }}>
                        <span className='material-symbols-rounded'>download</span>
                        Descargar CSV
                    </button>


                    {/* <div className='input-container'>
                        <select id="abogado" name="abogado" value={search} onChange={e => setSearch(e.target.value)}>
                            <option value="" disabled>---Selecciona Abogado---</option>
                            {
                                adminList.map(d =>
                                    <option key={d.key} value={d.key}>{d.name}</option>
                                )
                            }
                        </select>
                    </div> */}

                    {/* <input className='input-normal' id={"search"} type="text" value={search} onChange={e => setSearch(e.target.value)} />
                    <label className='input-label' htmlFor={"search"}>Buscar</label> */}
                </>
            }


            {/* Input text field for search ultimosCAsos, I need to filer ultimosCAsos array */}

            <h3 style={{ marginTop: '1rem' }}>Prioritarios</h3>

            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Ver</th>
                            {/* <th></th> */}
                            <th>Afiliado</th>
                            <th>Nombre</th>
                            <th>Fecha importante</th>
                            <th>Abogado</th>
                            <th>Creación</th>
                            <th>Acción</th>
                            <th>Tipo</th>
                            <th>Proceso</th>
                            <th>Id</th>
                            {/* <th>Ver</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {ultimosCasos
                            .filter(caso => {
                                var fechaImportante = caso.fechaImportante ? new Date(caso.fechaImportante.seconds * 1000) : null;
                                var isSoon = caso.fechaImportante ? (fechaImportante.getTime() - new Date().getTime()) <= 7 * 24 * 60 * 60 * 1000 : false;
                                return isSoon || caso.visto === false;
                            })
                            .sort((a, b) => {
                                var fechaImportanteA = a.fechaImportante ? new Date(a.fechaImportante.seconds * 1000) : new Date(0);
                                var fechaImportanteB = b.fechaImportante ? new Date(b.fechaImportante.seconds * 1000) : new Date(0);
                                return fechaImportanteA - fechaImportanteB;
                            })
                            .map(function (caso) {
                                var fechaImportante = caso.fechaImportante ? new Date(caso.fechaImportante.seconds * 1000) : null;
                                var isSoon = caso.fechaImportante ? (fechaImportante.getTime() - new Date().getTime()) <= 7 * 24 * 60 * 60 * 1000 : null; // 7 days in milliseconds
                                var fechaCreacion = new Date(caso.fechaCreacion.seconds * 1000).toLocaleDateString();
                                var admin = adminList.find(admin => admin.key === caso.abogado);
                                const defaultAvatarURL = "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png";
                                var photoURL = admin ? admin.photoURL : defaultAvatarURL;
                                var name = admin ? admin.name : '';
                                var visto = caso.visto;

                                var nombreDeAfiliado = afiliadoNombres[caso.afiliado] || 'Consultando...';

                                return (
                                    <tr key={caso.id} className={isSoon ? `${styles.soon_row}` : ''} style={{ position: 'relative' }}>
                                        <td><Link to={`/caso-legal?caso=${caso.id}`}>
                                            {
                                                visto === false &&
                                                <span className={`${styles.icon_badge}`}></span>
                                            }
                                            <span className='material-symbols-rounded'>open_in_new</span>
                                        </Link></td>
                                        {/* <td>
                                            <img src={photoURL} className={styles.avatar} />
                                        </td> */}
                                        <td>{caso.afiliado}</td>
                                        <td>{nombreDeAfiliado}</td>
                                        <td>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem'
                                            }}>
                                                <span className='material-symbols-rounded'>
                                                    {isSoon ? 'warning' : 'event'}
                                                </span>
                                                {
                                                    fechaImportante ?
                                                        fechaImportante.toLocaleDateString() :
                                                        'No definida'
                                                }
                                            </div>
                                        </td>
                                        <td>{name}</td>
                                        <td>{fechaCreacion}</td>
                                        <td>
                                            <div style={{
                                                display: 'flex',
                                            }}>
                                                {
                                                    caso.accion === 'ANEP' &&
                                                    <span className='material-symbols-rounded'>
                                                        priority_high
                                                    </span>
                                                }
                                                {caso.accion}
                                            </div>
                                        </td>
                                        <td>{caso.tipo}</td>
                                        <td>{caso.proceso}</td>
                                        <td>
                                            <code>
                                                {caso.id}
                                            </code>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>


            {/* <h3 style={{ marginTop: '1rem' }}>No Prioritarios</h3>

            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Ver</th>
                            <th>Afiliado</th>
                            <th>Nombre</th>
                            <th>Fecha importante</th>
                            <th>Abogado</th>
                            <th>Creación</th>
                            <th>Acción</th>
                            <th>Tipo</th>
                            <th>Proceso</th>
                            <th>Id</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ultimosCasos
                            .filter(caso => {
                                var fechaImportante = caso.fechaImportante ? new Date(caso.fechaImportante.seconds * 1000) : null;
                                var isSoon = caso.fechaImportante ? (fechaImportante.getTime() - new Date().getTime()) <= 7 * 24 * 60 * 60 * 1000 : false;
                                var isActive = caso.estado === 'ACTIVO';
                                return !isSoon && caso.visto === true && isActive;
                            })
                            .sort((a, b) => {
                                var fechaImportanteA = a.fechaImportante ? new Date(a.fechaImportante.seconds * 1000) : new Date(0);
                                var fechaImportanteB = b.fechaImportante ? new Date(b.fechaImportante.seconds * 1000) : new Date(0);
                                return fechaImportanteA - fechaImportanteB;
                            })
                            .map(function (caso) {
                                var fechaImportante = caso.fechaImportante ? new Date(caso.fechaImportante.seconds * 1000) : null;
                                var isSoon = caso.fechaImportante ? (fechaImportante.getTime() - new Date().getTime()) <= 7 * 24 * 60 * 60 * 1000 : null; // 7 days in milliseconds
                                var fechaCreacion = new Date(caso.fechaCreacion.seconds * 1000).toLocaleDateString();
                                var admin = adminList.find(admin => admin.key === caso.abogado);
                                const defaultAvatarURL = "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png";
                                var photoURL = admin ? admin.photoURL : defaultAvatarURL;
                                var name = admin ? admin.name : '';
                                var visto = caso.visto;

                                var nombreDeAfiliado = afiliadoNombres[caso.afiliado] || 'Consultando...';

                                return (
                                    <tr key={caso.id} className={isSoon ? `${styles.soon_row}` : ''} style={{ position: 'relative' }}>
                                        <td><Link to={`/caso-legal?caso=${caso.id}`}>
                                            {
                                                visto === false &&
                                                <span className={`${styles.icon_badge}`}></span>
                                            }
                                            <span className='material-symbols-rounded'>open_in_new</span>
                                        </Link></td>

                                        <td>{caso.afiliado}</td>
                                        <td>{nombreDeAfiliado}</td>
                                        <td>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem'
                                            }}>
                                                <span className='material-symbols-rounded'>
                                                    {isSoon ? 'warning' : 'event'}
                                                </span>
                                                {
                                                    fechaImportante ?
                                                        fechaImportante.toLocaleDateString() :
                                                        'No hay'
                                                }
                                            </div>
                                        </td>
                                        <td>{name}</td>
                                        <td>{fechaCreacion}</td>
                                        <td>
                                            <div style={{
                                                display: 'flex',
                                            }}>
                                                {
                                                    caso.accion === 'ANEP' &&
                                                    <span className='material-symbols-rounded'>
                                                        priority_high
                                                    </span>
                                                }
                                                {caso.accion}
                                            </div>
                                        </td>
                                        <td>{caso.tipo}</td>
                                        <td>{caso.proceso}</td>
                                        <td>
                                            <code>
                                                {caso.id}
                                            </code>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div> */}

            <h3>Todos los casos</h3>

            <div className='table-container'>
                <table className='table'>

                    <thead>
                        <tr>
                            <th>Ver</th>
                            <th>Afiliado</th>
                            <th>Nombre</th>
                            <th>Fecha importante</th>
                            <th>Abogado</th>
                            <th>Creación</th>
                            <th>Acción</th>
                            <th>Tipo</th>
                            <th>Proceso</th>
                            <th>Id</th>
                        </tr>
                    </thead>

                    <tbody>
                        {ultimosCasos.sort((a, b) => {
                            var fechaCreacionA = new Date(a.fechaCreacion.seconds * 1000);
                            var fechaCreacionB = new Date(b.fechaCreacion.seconds * 1000);
                            return fechaCreacionB - fechaCreacionA;

                        }).map(function (caso) {

                            var fechaImportante = caso.fechaImportante ? new Date(caso.fechaImportante.seconds * 1000) : null;

                            var isSoon = caso.fechaImportante ? (fechaImportante.getTime() - new Date().getTime()) <= 7 * 24 * 60 * 60 * 1000 : null; // 7 days in milliseconds

                            var fechaCreacion = new Date(caso.fechaCreacion.seconds * 1000).toLocaleDateString();

                            // compare caso.abogado with admins to get photoURL
                            var admin = adminList.find(admin => admin.key === caso.abogado);
                            const defaultAvatarURL = "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png";
                            var photoURL = admin ? admin.photoURL : defaultAvatarURL;
                            // do the same with name
                            var name = admin ? admin.name : '';

                            var visto = caso.visto;

                            var nombreDeAfiliado = afiliadoNombres[caso.afiliado] || 'Consultando...';


                            return (
                                <tr key={caso.id} className={isSoon ? `${styles.soon_row}` : ''} style={{ position: 'relative' }}>
                                    <td><Link to={`/caso-legal?caso=${caso.id}`}>
                                        {
                                            visto === false &&
                                            <span className={`${styles.icon_badge}`}></span>
                                        }
                                        <span className='material-symbols-rounded'>open_in_new</span>
                                    </Link></td>

                                    <td>{caso.afiliado}</td>
                                    <td>{nombreDeAfiliado}</td>
                                    <td>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem'
                                        }}>
                                            <span className='material-symbols-rounded'>
                                                {isSoon ? 'warning' : 'event'}
                                            </span>
                                            {
                                                fechaImportante ?
                                                    fechaImportante.toLocaleDateString() :
                                                    'No hay'
                                            }
                                        </div>
                                    </td>
                                    <td>{name}</td>
                                    <td>{fechaCreacion}</td>
                                    <td>
                                        <div style={{
                                            display: 'flex',
                                        }}>
                                            {
                                                caso.accion === 'ANEP' &&
                                                <span className='material-symbols-rounded'>
                                                    priority_high
                                                </span>
                                            }
                                            {caso.accion}
                                        </div>
                                    </td>
                                    <td>{caso.tipo}</td>
                                    <td>{caso.proceso}</td>
                                    <td>
                                        <code>
                                            {caso.id}
                                        </code>
                                    </td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </div>


            <button className='text-button' onClick={() => setSelection('ARCHIVADO')}>
                <span className='material-symbols-rounded'>archive</span>
                Ver casos archivados
            </button>
            {/* <section>
                <h2>Crear caso</h2>
                <div className='input-container'>
                    <input className='input-normal' id={"afiliado"} type="text" value={afiliado} onChange={e => setAfiliado(e.target.value.toLocaleUpperCase())} />
                    <label className='input-label' htmlFor={"afiliado"}>Afiliado</label>
                </div>
                <p>
                    Nombre: {afiliadoNombre}<br />
                    Apellido: {afiliadoApellidos}
                </p>
                <select id="tipo" name="tipo" value={tipo} onChange={e => setTipo(e.target.value)}>
                    <option value="A">A - Contencioso Administrativo</option>
                    <option value="B">B - Reclamos administrativos</option>
                    <option value="C">C - Acciones de inconstitucionalidad</option>
                    <option value="D">D - Administrativo disciplinario</option>
                </select>
                <button className='filled-button' onClick={crearCaso}>Crear caso</button>
            </section> */}

            <LegalCreateDialog />

        </div>
    );
}

function LegalCreateDialog() {
    const [afiliado, setAfiliado] = useState('');
    const [afiliadoExiste, setAfiliadoExiste] = useState(false);
    const [tipo, setTipo] = useState('');
    const [proceso, setProceso] = useState('');
    // Display reference
    const [afiliadoNombre, setAfiliadoNombre] = useState('');
    const [afiliadoApellidos, setAfiliadoApellidos] = useState('');
    // INSTITUCIONES
    const [listaInstituciones, setListaInstituciones] = useState([]);
    const [institucion, setInstitucion] = useState('');

    useEffect(() => {
        // when afiliado changes and length is more than 7 characters, query firestore on usuarios collection and get the name and last name, you should compare afiliado with doc.id

        async function fetchData() {
            const docRef = doc(db, "usuarios", afiliado);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setAfiliadoNombre(docSnap.data().nombre);
                setAfiliadoApellidos(docSnap.data().apellidos);
                setAfiliadoExiste(true);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                setAfiliadoNombre('');
                setAfiliadoApellidos('');
                setAfiliadoExiste(false);
            }
        }

        if (afiliado.length > 7) {
            fetchData();
        }
    }, [afiliado]);

    useEffect(() => {
        async function fetchData() {
            const docRef = doc(db, "instituciones", "lista");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data().instituciones);
                setListaInstituciones(docSnap.data().instituciones.sort());
            } else {
                console.log("No such document!");
            }
        }
        fetchData();
    }, []);

    const instList = listaInstituciones.map(data =>
        <option key={data} value={data}>{data}</option>
    );

    const crearCaso = async () => {
        try {
            const docRef = await addDoc(collection(db, "legales"), {
                ...(tipo === 'COLECTIVO' ? { institucion: institucion } : { afiliado: afiliado }),
                tipo: tipo,
                estado: 'POR ASIGNAR',
                visto: false,
                fechaCreacion: Timestamp.now(),
            });
            console.log("Document written with ID: ", docRef.id);
            closeDialog();
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    }

    const closeDialog = () => {
        var dialog = document.getElementById("legal-create-modal");
        dialog.classList.add(styles.close);
        const animationEndHandler = (event) => {
            dialog.close();
            dialog.classList.remove(styles.close);
            dialog.removeEventListener("animationend", animationEndHandler);
        };
        dialog.addEventListener("animationend", animationEndHandler);
    };

    const handleBackdropClick = (e) => {
        var dialog = document.getElementById("legal-create-modal");
        if (e.target === dialog) {
            dialog.classList.add(styles.close);
            const animationEndHandler = (event) => {
                dialog.close();
                dialog.classList.remove(styles.close);
                dialog.removeEventListener("animationend", animationEndHandler);
            };
            dialog.addEventListener("animationend", animationEndHandler);
        }
    };

    return (
        <dialog
            id={"legal-create-modal"}
            className={styles.dialog}
            onClick={handleBackdropClick}
        >
            <div className={styles.container}>

                <button className={`icon-button ${styles.x_button}`} onClick={closeDialog}>
                    <span className="material-symbols-rounded">close</span>
                </button>

                <div className={styles.header}>
                    <span className="material-symbols-rounded">work</span>
                    <h3>Crear caso legal</h3>
                </div>

                <div className={styles.content}>

                    <div className='segmented-button-container'>
                        <button className={tipo === 'INDIVIDUAL' ? 'segmented-button active' : 'segmented-button'} onClick={() => setTipo('INDIVIDUAL')}>
                            {
                                tipo === 'INDIVIDUAL' ?
                                    <span className="material-symbols-rounded">check</span> :
                                    <span className="material-symbols-rounded">person</span>
                            }
                            Individual
                        </button>
                        <button className={tipo === 'COLECTIVO' ? 'segmented-button active' : 'segmented-button'} onClick={() => setTipo('COLECTIVO')}>
                            {
                                tipo === 'COLECTIVO' ?
                                    <span className="material-symbols-rounded">check</span> :
                                    <span className="material-symbols-rounded">groups</span>
                            }
                            Colectivo
                        </button>
                    </div>

                    {
                        tipo === 'INDIVIDUAL' &&
                        <>
                            <div className='input-container'>
                                <input className='input-normal' id={"afiliado"} type="text" value={afiliado} onChange={e => setAfiliado(e.target.value.toLocaleUpperCase())} />
                                <label className='input-label' htmlFor={"afiliado"}>Afiliado</label>
                            </div>
                            <p>
                                Nombre: {afiliadoNombre}<br />
                                Apellido: {afiliadoApellidos}
                            </p>
                        </>
                    }

                    {
                        tipo === 'COLECTIVO' &&
                        <>
                            <h4 style={{ margin: '1rem 0', display: 'flex', gap: '0.5rem' }}>
                                <span className='material-symbols-rounded'>
                                    link
                                </span>
                                Institucion {institucion}
                            </h4>
                            <div className='input-container'>
                                <select id="select-institucion" value={institucion} onChange={e => setInstitucion(e.target.value)} style={{ width: '200px' }}>
                                    <option value="" disabled>---Selecciona una Institucion---</option>
                                    {instList}
                                </select>
                            </div>
                        </>
                    }

                    {/* <select id="tipo" name="tipo" value={tipo} onChange={e => setTipo(e.target.value)}>
                        <option value="A">A - Contencioso Administrativo</option>
                        <option value="B">B - Reclamos administrativos</option>
                        <option value="C">C - Acciones de inconstitucionalidad</option>
                        <option value="D">D - Administrativo disciplinario</option>
                    </select> */}

                    {/* <div className='input-container'>
                        <select id="proceso" name="proceso" value={proceso} onChange={e => setProceso(e.target.value)}>
                            <option value="" disabled>---Selecciona un Proceso---</option>
                            <optgroup label="Procesos judiciales">
                                <option value="A1">A1 - Ordinario laborales</option>
                                <option value="A2">A2 - Procesos especiales de protección</option>
                                <option value="A3">A3 - Infracciones a las leyes laborales</option>
                                <option value="A4">A4 - Acoso laboral</option>
                                <option value="A5">A5 - Riesgos de trabajo</option>
                                <option value="A6">A6 - Contencioso administrativo</option>
                                <option value="A7">A7 - Recursos de amparo</option>
                                <option value="A8">A8 - Acciones de inconstitucionalidad</option>
                            </optgroup>
                            <optgroup label="Vía administrativos">
                                <option value="B1">B1 - Disciplinario</option>
                                <option value="B2">B2 - Reclamos</option>
                                <option value="B3">B3 - Conciliaciones MTSS</option>
                            </optgroup>
                            <optgroup label="Criterios">
                                <option value="C1">C1 - En trámite</option>
                                <option value="C2">C2 - Realizado</option>
                            </optgroup>
                        </select>
                    </div> */}
                </div>

                <div className={styles.actions}>
                    <button className='outlined-button' onClick={closeDialog}>Cancelar</button>
                    {/* <button className='filled-button' onClick={crearCaso} disabled={!afiliadoExiste}>Crear caso</button> */}
                    <button className='filled-button' onClick={crearCaso} disabled={(tipo === 'INDIVIDUAL' && !afiliadoExiste) || (tipo === 'COLECTIVO' && !institucion)}>Crear caso</button>
                </div>

            </div>
        </dialog>
    );
}